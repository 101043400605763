import Chat from '../images/live-chat.png';
import Facebook from '../images/facebook.png';
import Cleaning from '../images/cleaning.png';
import CarpetCleaning from '../images/carpet-cleaning.png';
import QuickCleaning from '../images/quick-clean.png';
import Dashboard from '../images/Dashboard.png';
import WeHere from '../images/weHere.png';
import Noosa from '../images/noosa-logo.png';
import Sunshine from '../images/sunshine-logo.png';
import Load from '../images/load-logo.png';
import Nannies from '../images/nannies-logo.png';
import AppStore from '../images/app-store.png';
import PlayStore from '../images/play-store.png';
import Task from '../images/Task.png';
import BemovedLogo from '../images/logo_white.png';
import Linkedin from '../svgs/linkedin.svg';
import Cross from '../svgs/cross.svg';
import Apple from '../svgs/apple.svg';
import FirstSectionImage from '../svgs/firstSectionImg.svg';
import Schedule from '../svgs/scheduling.svg';
import Marketing from '../svgs/marketing-strategies.svg';
import Google from '../svgs/google.svg';
import QomenBloon from '../svgs/women-bloon.svg';
import Car from '../svgs/Car.svg';
import UserGroup from '../svgs/UserGroup.svg';
import Shop from '../svgs/Shop.svg';
import Edit from '../svgs/Edit.svg';
import EditOverview from '../svgs/edit-overview-sp.svg';
import Delete from '../svgs/Delete.svg';
import Stripe from '../svgs/Stripe.svg';
import Image from '../svgs/Image.svg';
import User from '../svgs/User.svg';
import EditWhite from '../svgs/EditWhite.svg';
import Tick from '../svgs/Tick.svg';
import House from '../svgs/House.svg';
import ArrowPointer from '../svgs/ArrowPointer.svg';
import Dollar from '../svgs/Dollar.svg';
import Like from '../svgs/Like.svg';
import View from '../svgs/View.svg';
import MyAccount from '../svgs/my-account.svg';
import Property from '../svgs/property.svg';
import Household from '../svgs/household.svg';
import Vehicle from '../svgs/vehicle.svg';
import Pet from '../svgs/pet.svg';
import Circle from '../svgs/circle.svg';
import EmptyCircle from '../svgs/empty-circle.svg';
import Avatar1 from '../svgs/avatar1.svg';
import Avatar2 from '../svgs/avatar2.svg';
import Avatar3 from '../svgs/avatar3.svg';
import Avatar4 from '../svgs/avatar4.svg';
import Avatar5 from '../svgs/avatar5.svg';
import Avatar6 from '../svgs/avatar6.svg';
import Adult from '../svgs/adult.svg';
import Children from '../svgs/children.svg';
import HouseholdImg from '../svgs/household-img.svg';
import Cat from '../svgs/cat.svg';
import BusinessShop from '../svgs/BusinessShop.svg';
import Home from '../svgs/Home.svg';
import MyTasks from '../svgs/MyTasks.svg';
import Calender from '../svgs/Calender.svg';
import Calendar2 from '../svgs/calendar2.svg';
import Messages from '../svgs/Messages.svg';
import MarketPlace from '../svgs/MarketPlace.svg';
import Favourites from '../svgs/Favourites.svg';
import Guides from '../svgs/Guides.svg';
import MountainGuides from '../svgs/MountainGuides.svg';
import Arrow from '../svgs/Arrow.svg';
import Settings from '../svgs/Settings.svg';
import Megaphone from '../svgs/Megaphone.svg';
import WhiteCross from '../svgs/WhiteCross.svg';
import Info from '../svgs/Info.svg';
import GoogleCalender from '../svgs/GoogleCalender.svg';
import GoogleReviews from '../svgs/GoogleReviews.svg';
import Instagram from '../svgs/Instagram.svg';
import Splash from '../svgs/Splash.svg';
import BuildingTown from '../svgs/BuildingTown.svg';
import AmericanExpress from '../svgs/AmericanExpress.svg';
import Visa from '../svgs/Visa.svg';
import MasterCard from '../svgs/MasterCard.svg';
import CVCCard from '../svgs/CVCCard.svg';
import BusinessWomen from '../svgs/BusinessWomen.svg';
import CarWithTree from '../svgs/CarWithTree.svg';
import ImageBlack from '../svgs/ImageBlack.svg';
import Room from '../svgs/Room.svg';
import TreeRoom from '../svgs/TreeRoom.svg';
import Plus from '../svgs/Plus.svg';
import PlusWithoutCircle from '../svgs/PlusWithoutCircle.svg';
import Minus from '../svgs/Minus.svg';
import ItemBox from '../svgs/Box.svg';
import LivingRoom from '../svgs/LivingRoom.svg';
import Donate from '../svgs/Donate.svg';
import GeneratingPlan from '../svgs/GeneratingPlan.svg';
import RequestAccess from '../svgs/RequestAccess.svg';
import StoreCatelogue from '../svgs/StoreCatelogue.svg';
import SellCatelogue from '../svgs/SellCatelogue.svg';
import MoveCatelogue from '../svgs/MoveCatelogue.svg';
import DisposeCatelogue from '../svgs/DisposeCatelogue.svg';
import BusinessAccount from '../svgs/business-account.svg';
import Factory from '../svgs/factory.svg';
import Navigation from '../svgs/navigation.svg';
import Globe from '../svgs/Globe.svg';
import Search from '../svgs/search.svg';
import CreatePlan1 from '../svgs/CreatePlan-1.svg';
import BusinessProfile from '../svgs/Business-profile.svg';
import BusinessProfiled from '../svgs/Business-profiled.svg';
import BusinessProfileded from '../svgs/Business-profileded.svg';
import JustBrowsing from '../svgs/just-browsing.svg';
import LookingToMove from '../svgs/looking-to-move.svg';
import ReadyToMove from '../svgs/ready-to-move.svg';
import SendArrow from '../svgs/send-arrow.svg';
import Calendar from '../svgs/calendar.svg';
import Idea from '../svgs/idea.svg';
import CreatePlan from '../svgs/create-plan.svg';
import SaveProfileIcon from '../svgs/save-profile-icon.svg';
import Upload from '../svgs/Upload.svg';
import UserProfile from '../svgs/User-profile.svg';
import X from '../svgs/X.svg';
import Dog from '../svgs/dog.svg';
import AvatarImg from '../images/Avatar.png';
import ImageIcon from '../svgs/image-black.svg';
import ImageIconBlue from '../svgs/Image.svg';
import ImageIconWhite from '../svgs/image-white.svg';
import MovingTo from '../svgs/moving-to.svg';
import MovingFrom from '../svgs/moving-from.svg';
import Bed from '../svgs/bed.svg';
import Bath from '../svgs/bath.svg';
import CarIcon from '../svgs/carIcon.svg';
import Area from '../svgs/area.svg';
import Agent from '../images/agent.png';
import Dijones from '../images/dijones.png';
import ChatNow from '../svgs/chat.svg';
import PropertyImg from '../images/property-img.jpg';
import InviteAgent from '../svgs/invite-agent.svg';
import Agent1 from '../images/agent1.png';
import Agent2 from '../images/agent2.png';
import MeetTheTeam from '../svgs/meet_the_team.svg';
import MyProperty from '../svgs/MyProperty.svg';
import Verified from '../svgs/verified.svg';
import YellowCheck from '../svgs/Yellow-Check.svg';
import YellowUncheck from '../svgs/Yellow-Uncheck.svg';
import AvatarGirl from '../images/AvatarGirl.png';
import Check from '../svgs/Check.svg';
import Checkmark from '../svgs/Checkmark.svg';
import ImagePlaceholder from '../images/no-image.png';
import DeleteIcon from '../svgs/delete-icon.svg';
import CloseWhite from '../svgs/close-white.svg';
import Sunrise from '../svgs/sunrise.svg';
import Sun from '../svgs/sun.svg';
import Moon from '../svgs/moon.svg';
import Image1 from '../images/image1.png';
import Image2 from '../images/image2.png';
import CloseGray from '../svgs/close-gray.svg';
import InfoDark from '../svgs/info-dark.svg';
import RecievingRequest from '../svgs/recieving-request.svg';
import ImgBox from '../images/img-box.png';
import CalendarCheck from '../svgs/calendarCheck.svg';
import Clock from '../svgs/Clock.svg';
import Accept from '../svgs/accept.svg';
import BookingImg from '../svgs/bookingImg.svg';
import ArrowDown from '../svgs/ArrowDown.svg';
import NoService from '../svgs/no-service.svg';
import AddNewJobHeader from '../svgs/AddNewJobHeader.svg';
import Checked from '../svgs/Checked.svg';
import PlusBlue from '../svgs/PlusBlue.svg';
import Pinned from '../svgs/pinned.svg';
import User1 from '../images/user1.png';
import User2 from '../images/user2.png';
import User3 from '../images/user3.png';
import MoreDotts from '../svgs/more-dotts.svg';
import Eye from '../svgs/eye.svg';
import PinnedGray from '../svgs/pinned-gray.svg';
import Mute from '../svgs/mute.svg';
import Attachment1 from '../images/attachment1.png';
import Attachment2 from '../images/attachment2.png';
import Attachment3 from '../images/attachment3.png';
import AddImage from '../svgs/addImage.svg';
import Link from '../svgs/Link.svg';
import Emoji from '../svgs/emoji.svg';
import SendWhite from '../svgs/sendWhite.svg';
import Checkbox from '../svgs/Checkbox.svg';
import ArrowUp from '../svgs/ArrowUp.svg';
import AgentCompany from '../svgs/AgentCompany.svg';
import ArrowRight from '../svgs/ArrowRight.svg';
import PayPal from '../svgs/PayPal.svg';
import HeaderImg from '../svgs/header-img.svg';
import Wemoved from '../svgs/what-wemoved.svg';
import DashboardImg from '../svgs/dashboard-img.svg';
import AvatarImgIcon from '../svgs/avatarIcon.svg';
import Noosax1 from '../images/noosa-logox1.png';
import Sunshinex1 from '../images/sunshine-logox1.png';
import Loadx1 from '../images/load-logox1.png';
import Nanniesx1 from '../images/nannies-logox1.png';
import PhoneImg from '../svgs/phoneImg.svg';
import DashboardLaptop from '../svgs/dashboard-laptop.svg';
import ActiveSupport from '../svgs/active-support.svg';
import MovePlus from '../svgs/movePlus.svg';
import Raine from '../images/raine.png';
import AvatarGray from '../svgs/Avatar-gray.svg';
import NotePad from '../svgs/notepad.svg';
import UserBusiness from '../svgs/User-Business.svg';
import AddWithCircle from '../svgs/AddWithCircle.svg';
import BlueCross from '../svgs/BlueCross.svg';
import DashboardIcon from '../svgs/dashboard.svg';
import FishIcon from '../svgs/fish.svg';
import RabbitIcon from '../svgs/rabbit.svg';
import BirdIcon from '../svgs/bird.svg';
import UserGroupColor from '../svgs/UserGroupColor.svg';
import Achievement from '../svgs/Achievement.svg';
import DollarWithCircle from '../svgs/DollarWithCircle.svg';
import DollarWallet from '../svgs/DollarWallet.svg';
import AddBusiness from '../svgs/AddBusiness.svg';
import DeleteIconBlue from '../svgs/DeleteIconBlue.svg';
import locationMark from '../svgs/locationMark.svg';
import PeopleSearch from '../svgs/People_search.svg';
import LoadingIcon from '../svgs/loader.gif';
import InputTextArrow from '../svgs/inputTextArrow.svg';

import request from '../images/performance/request.svg';
import accepted from '../images/performance/accepted.svg';
import decline from '../images/performance/decline.svg';
import quoted from '../images/performance/quoted.svg';
import EmptyChatState from '../images/chat/empty-state.svg';
import AgentDashboard from '../images/agent/dashboard.svg';
import TotalConnectedCustomers from '../images/agent/connected-customers/total-connected-customers.svg';
import TotalSignedUp from '../images/agent/connected-customers/total-signed-up.svg';
import TotalTransacted from '../images/agent/connected-customers/total-transacted.svg';
import InfoIcon from '../images/agent/connected-customers/info_icon.svg';

import DashboardActive from '../images/activeIcons/nav_bar/dashboard_active.svg';
import ChatIconActive from '../images/activeIcons/nav_bar/agent/chat_active.svg';
import ConnectedCustomerIconActive from '../images/activeIcons/nav_bar/agent/connected_customer_active.svg';
import MarketplaceIconActive from '../images/activeIcons/nav_bar/agent/marketplace_active.svg';

import CalenderActive from '../images/activeIcons/nav_bar/customer/calender_active.svg';
import MyTaskActive from '../images/activeIcons/nav_bar/customer/my_task_active.svg';
import PropertSearchActive from '../images/activeIcons/nav_bar/customer/property_search_active.svg';
import SettingActive from '../images/activeIcons/nav_bar/setting_active.svg';
import NoConnectedCustomers from '../images/agent/connected-customers/no_customer.svg';
import ArrowBlack from '../images/activeIcons/nav_bar/ArrowBlack.svg';
import EmptyRequestedPerformance from '../images/performance/empty/requested.svg';
import EmptyQuotedPerformance from '../images/performance/empty/quoted.svg';
import EmptyAcceptedPerformance from '../images/performance/empty/accepted.svg';
import EmptyDeclinedPerformance from '../images/performance/empty/declined.svg';
import BusinessAdmin from '../images/business-profile-admin.png';
import BusinessMember from '../images/business-profile-member.png';
import FileIcon from '../svgs/File.svg';

import Pig from '../svgs/Pig.svg';
import signupCustomer from '../svgs/signup.svg';
import CoreLogicLogo from '../images/CoreLogicLogo.png';

import forgotPassword from '../svgs/forgotpassword.svg';
import emailSend from '../svgs/emailsend.svg';
import logo from '../svgs/logo.svg';

export {
  MeetTheTeam,
  BirdIcon,
  FishIcon,
  RabbitIcon,
  DashboardIcon,
  NotePad,
  DeleteIcon,
  ImagePlaceholder,
  YellowCheck,
  YellowUncheck,
  FileIcon,
  Verified,
  MyProperty,
  BusinessProfileded,
  Apple,
  Cross,
  Chat,
  Google,
  Linkedin,
  Facebook,
  Cleaning,
  CarpetCleaning,
  QuickCleaning,
  BusinessProfiled,
  QomenBloon,
  Dashboard,
  WeHere,
  Noosa,
  Sunshine,
  Load,
  Nannies,
  AppStore,
  PlayStore,
  BemovedLogo,
  FirstSectionImage,
  Schedule,
  Marketing,
  Car,
  UserGroup,
  Shop,
  Edit,
  Delete,
  Stripe,
  Image,
  User,
  EditWhite,
  Tick,
  House,
  ArrowPointer,
  Like,
  View,
  Dollar,
  MyAccount,
  Property,
  Household,
  Vehicle,
  Pet,
  Circle,
  EmptyCircle,
  Avatar1,
  Avatar2,
  Avatar3,
  Avatar4,
  Avatar5,
  Avatar6,
  Adult,
  Children,
  HouseholdImg,
  Cat,
  BusinessShop,
  Home,
  MyTasks,
  Calender,
  Messages,
  MarketPlace,
  Favourites,
  Guides,
  MountainGuides,
  Arrow,
  Settings,
  Megaphone,
  WhiteCross,
  Info,
  Instagram,
  GoogleCalender,
  GoogleReviews,
  Splash,
  BuildingTown,
  Visa,
  AmericanExpress,
  MasterCard,
  CVCCard,
  BusinessWomen,
  CarWithTree,
  ImageBlack,
  Room,
  TreeRoom,
  Plus,
  PlusWithoutCircle,
  Minus,
  InputTextArrow,
  ItemBox,
  LivingRoom,
  Donate,
  GeneratingPlan,
  RequestAccess,
  MoveCatelogue,
  SellCatelogue,
  StoreCatelogue,
  DisposeCatelogue,
  BusinessAccount,
  Factory,
  Navigation,
  Globe,
  Search,
  CreatePlan1,
  BusinessProfile,
  JustBrowsing,
  LookingToMove,
  ReadyToMove,
  SendArrow,
  Calendar,
  Calendar2,
  Idea,
  CreatePlan,
  SaveProfileIcon,
  Upload,
  UserProfile,
  X,
  Dog,
  AvatarImg,
  ImageIcon,
  ImageIconBlue,
  ImageIconWhite,
  MovingTo,
  MovingFrom,
  Bed,
  Bath,
  CarIcon,
  Area,
  Agent,
  Dijones,
  ChatNow,
  PropertyImg,
  InviteAgent,
  Agent1,
  Agent2,
  AvatarGirl,
  Check,
  Checkmark,
  CloseWhite,
  Sunrise,
  Sun,
  Moon,
  Image1,
  Image2,
  CloseGray,
  InfoDark,
  RecievingRequest,
  ImgBox,
  CalendarCheck,
  Clock,
  Accept,
  BookingImg,
  ArrowDown,
  NoService,
  AddNewJobHeader,
  Checked,
  PlusBlue,
  Pinned,
  User1,
  User2,
  User3,
  MoreDotts,
  Eye,
  PinnedGray,
  Mute,
  Attachment1,
  Attachment2,
  Attachment3,
  AddImage,
  Link,
  Emoji,
  SendWhite,
  Task,
  Checkbox,
  ArrowUp,
  AgentCompany,
  ArrowRight,
  PayPal,
  PeopleSearch,
  HeaderImg,
  Wemoved,
  DashboardImg,
  AvatarImgIcon,
  Noosax1,
  Sunshinex1,
  Loadx1,
  Nanniesx1,
  PhoneImg,
  DashboardLaptop,
  ActiveSupport,
  MovePlus,
  Raine,
  AvatarGray,
  UserBusiness,
  AddWithCircle,
  BlueCross,
  UserGroupColor,
  Achievement,
  DollarWithCircle,
  DollarWallet,
  AddBusiness,
  DeleteIconBlue,
  locationMark,
  request,
  accepted,
  quoted,
  decline,
  EmptyChatState,
  AgentDashboard,
  TotalConnectedCustomers,
  TotalSignedUp,
  TotalTransacted,
  InfoIcon,
  DashboardActive,
  ChatIconActive,
  ConnectedCustomerIconActive,
  MarketplaceIconActive,
  CalenderActive,
  MyTaskActive,
  PropertSearchActive,
  SettingActive,
  NoConnectedCustomers,
  LoadingIcon,
  ArrowBlack,
  EmptyRequestedPerformance,
  EmptyQuotedPerformance,
  EmptyAcceptedPerformance,
  EmptyDeclinedPerformance,
  Pig,
  signupCustomer,
  forgotPassword,
  emailSend,
  logo,
  CoreLogicLogo,
  BusinessAdmin,
  BusinessMember,
  EditOverview,
};
